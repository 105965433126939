import { ParaglideJS } from "@inlang/paraglide-next/pages";
import { type AppType, type AppProps } from "next/app";
import Head from "next/head";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
// import { type ReactNode, useEffect, useMemo } from "react";
import { ClerkProvider } from "@clerk/nextjs";
import { esES, ptBR, enUS } from "@clerk/localizations";
import { MantineProvider, localStorageColorSchemeManager } from "@mantine/core";
import { theme } from "@/utils";
import { Notifications } from "@mantine/notifications";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
// import { LOCAL_STORAGE_LANG_KEY } from "@/utils";
// import { languageTag } from "@/paraglide/runtime";
import { DatesProvider } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";
import { RouterTransition } from "@/components/RouterTransition";
import { QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { queryClient } from "@/utils/client/queries/client";
import { PractitionerWeightProvider } from "@/contexts/PractitionerWeightProvider";

import "@mantine/core/styles.css";
import "mantine-datatable/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/nprogress/styles.css";
import "@mantine/charts/styles.css";

import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";

// Dayjs plugins
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const colorSchemeManager = localStorageColorSchemeManager({
  key: "alma-med-color-scheme",
});

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://us.i.posthog.com",
    person_profiles: "identified_only", // 'always' to create profiles for anonymous users as well or 'identified_only'
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
    autocapture: true,
  });
}
// posthog.capture('my event', { property: 'value' })
const pickClerkLocalization = (lang?: string) => {
  if (!lang || lang === "es") return esES;
  if (lang === "en") return enUS;
  if (lang === "pt") return ptBR;
  return esES;
};

const App: AppType = ({ Component, pageProps, router }: AppProps) => {
  const lang = router.locale;
  const clerkLocalization = pickClerkLocalization(lang);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <ParaglideJS>
            <MantineProvider
              theme={theme}
              defaultColorScheme="light"
              colorSchemeManager={colorSchemeManager}
            >
              <RouterTransition />
              <ClerkProvider
                {...pageProps}
                signInUrl="/sign-in"
                signUpUrl="/sign-up"
                localization={clerkLocalization}
              >
                <PractitionerWeightProvider>
                  <ModalsProvider>
                    <DatesProvider settings={{ locale: lang }}>
                      <Component {...pageProps} />
                    </DatesProvider>
                  </ModalsProvider>
                </PractitionerWeightProvider>
              </ClerkProvider>
              <Notifications />
            </MantineProvider>
          </ParaglideJS>
        </QueryClientProvider>
      </PostHogProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
