// import type { RouterOutputs } from "@/trpc/shared";
import { fmf } from "./fmf";
import { StudyType, StudySubType } from "@/lib/db";
import { un } from "./unitNum";
import { type Unit } from "mathjs";
import dayjs, { type Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import { d } from "./date";
import { type Patient, type StudyWithJsonData, type Pregnancy } from "@/types";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const TODAY = d();

export enum GaFrom {
  lmp = "lmp",
  crl = "crl",
  hc = "hc",
  lmpf = "lmpf",
}
export type GaAt = {
  weeks?: number | undefined;
  days?: number | undefined;
  at: dayjs.Dayjs;
  from: GaFrom;
};

const Ranks = {
  crl: 1,
  hc: 2,
  fumc: 3,
};
const HCRanking = Ranks.hc;
interface GaStudy extends StudyWithJsonData {
  ga: number;
  rank: number;
  from: GaFrom;
}

// Obtengo la GA más confiable de un estudio
export const getGaFromStudy = (study: StudyWithJsonData) => {
  const crl = un(study.data.CRL)?.to("mm").toNumber();
  const hc = un(study?.data?.HC)?.to("mm").toNumber();

  // Tiene crl registrado
  if (crl) {
    const ga = fmf.gaFromCrl(crl, 0, 84);
    if (ga) return { ga, rank: Ranks.crl, from: GaFrom.crl };
  }

  // Tiene hc registrado
  if (hc) {
    const ga = fmf.gaFromHc(hc);
    if (ga) return { ga, rank: HCRanking, from: GaFrom.hc };
  }

  return null;
};

const isStudyWithinRange = (x: Date, a: Dayjs, b: Dayjs) => {
  return d(x).isBetween(a, b, "day", "[]");
};

const isObUltrasound = (study: StudyWithJsonData) => {
  return (
    study.type === StudyType.pregnancy &&
    study.subType === StudySubType.obstetricUltrasound
  );
};

const selectBestRankedStudy = (studiesWithRank: GaStudy[]) => {
  if (!studiesWithRank.length || !studiesWithRank[0]) return null;

  // order by rank
  const orderedByRank = [...studiesWithRank].sort((a, b) => a.rank - b.rank);

  if (!orderedByRank[0]) return null;

  const topRank = orderedByRank[0].rank;
  // Más de un candidato con el mismo rango (x2 con hc o x3 con crl)
  const candidates = orderedByRank.filter((study) => study.rank === topRank);
  const firstCandidate = candidates[0];

  if (!firstCandidate) return null;

  return candidates.length > 1
    ? // Hay más de un estudio con el mismo ranking
      candidates.reduce<GaStudy>((curr, study) => {
        if (topRank === Ranks.hc) {
          // Devuelvo la del de hc más chico
          if (
            curr.data.HC?.value &&
            study.data.HC?.value &&
            study.data.HC?.value < curr.data.HC?.value
          )
            return study;
          return curr;
        } else if (topRank === Ranks.crl) {
          // devuelvo la del de crl más grande
          if (
            curr.data.CRL?.value &&
            study.data.CRL?.value &&
            study.data.CRL?.value > curr.data.CRL?.value
          )
            return study;
          return curr;
        }
        return curr;
      }, firstCandidate)
    : // Hay un solo candidato  con el ranking ganador
      firstCandidate;
};

export const getPregnancyGA = (
  currentPregnancy?: Pregnancy,
  studies?: StudyWithJsonData[]
): GaAt | null => {
  // If no on-going pregnancy return null
  if (!currentPregnancy || !studies) return null;

  //  // GA desde fecha última menstruación
  const lmp = fmf.gaFromLmp(currentPregnancy.lastMenstrualPeriodDate);
  const currPregnancyStart = d(
    currentPregnancy.lastMenstrualPeriodDate ?? currentPregnancy.createdAt
  );

  // Extend studies list with rank according to ga source
  const studiesWithRank = studies.reduce((acc: GaStudy[], study) => {
    // Dejo afuera los estudios que NO son de este embarazo o no son ecografías
    if (
      !isStudyWithinRange(study.date, currPregnancyStart, TODAY) ||
      !isObUltrasound(study)
    )
      return acc;

    // Obtengo { ga, gaFrom, rank }
    const result = getGaFromStudy(study);
    // Si hay resultado es porque pude extraer alguna GA
    if (result) return [...acc, { ...study, ...result }];

    return acc;
  }, []);

  const study = selectBestRankedStudy(studiesWithRank);

  const ga = study ? study.ga : lmp;
  const gaAt = study ? d(study.date) : TODAY;
  const from = study ? study.from : GaFrom.lmp;

  const parsedGa = fmf.daysToOWObject(ga);

  return {
    ...parsedGa,
    at: gaAt,
    from,
  };
};

export const getPregnancyGAToday = (
  currentPregnancy?: Pregnancy | null,
  studies?: StudyWithJsonData[]
) => {
  const today = d().toDate();
  const ga = getPregnancyGAAt(currentPregnancy, studies, today);
  if (!ga) return null;
  return `${ga.weeks}+${ga.days} Semanas`;
};

export const getGaAt = (
  ga?: GaAt | null,
  date: string | Date = d().format()
) => {
  if (!ga || !date) return null;
  const { weeks, days, at } = ga;
  if (!weeks || !Number.isInteger(days) || !at) return null;

  // Add the diff between GA date and now.
  const refDate = d(date);
  const diff = dayjs.duration(refDate.diff(at)).as("days");
  const totalDays = dayjs
    .duration(weeks, "weeks")
    .add(days!, "days")
    .add(diff, "days")
    .as("days");

  const parsedGa = fmf.daysToOWObject(totalDays);
  // const parsedGa = fmf.daysToOW(totalDays);

  return parsedGa;
};

export const getPregnancyGAAt = (
  currentPregnancy?: Pregnancy | null,
  studies?: StudyWithJsonData[],
  date?: string | Date
) => {
  if (!currentPregnancy || !studies) return null;
  const lastGa = getPregnancyGA(currentPregnancy, studies);
  if (!lastGa) return null;

  const { weeks, days, at } = lastGa;
  if (!weeks || !days || !at) return null;
  // Add the diff between GA date and now.
  const refDate = dayjs.utc(date);
  const diff = dayjs.duration(refDate.diff(at)).as("days");
  const totalDays = dayjs
    .duration(weeks, "weeks")
    .add(days, "days")
    .add(diff, "days")
    .as("days");

  const parsedGa = fmf.daysToOWObject(totalDays);

  return {
    ...parsedGa,
    at,
  };
};

export const getCurrentPregnancy = (pregnancies?: Pregnancy[] | null) => {
  // on-going | No puede tener más de uno asique filter está ok.
  if (!pregnancies) return null;
  return pregnancies
    ? pregnancies?.find((p) => !p.endDate && !p.newbornBirthDate)
    : null;
};

export const getLastFinishedPregnancy = (
  pregnancies?: Pregnancy[]
): Pregnancy | null => {
  if (!pregnancies) return null;
  return pregnancies
    ? pregnancies
        .filter((p) => p.endDate)
        .reduce<Pregnancy | null>((a, b) => {
          const aEndDate = a?.endDate ?? 0;
          const bEndDate = a?.endDate ?? 0;
          return aEndDate > bEndDate ? a : b;
        }, null)
    : null;
};

export const getCurrentPregnancyEFW = (
  currentPregnancy?: Pregnancy | null,
  studies?: StudyWithJsonData[]
) => {
  // on-going
  // If no on-going pregnancy return null
  if (!currentPregnancy || !studies) return null;

  const studyType = "pregnancy";
  const studySubType = "obstetricUltrasound";

  const filteredStudies = studies?.filter(
    ({ type, subType }) => type === studyType && subType === studySubType
  );

  const sortedStudies = filteredStudies?.sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );

  if (!sortedStudies || sortedStudies.length === 0) return null;

  let efw: Unit | null | undefined;
  let studyDate: Date | undefined = undefined;
  // 1. Get Fetal weight from last study
  sortedStudies.some((study) => {
    const { data } = study;

    const hc = un(data.HC),
      ac = un(data.AC),
      fl = un(data.FL);
    if (hc && ac && fl) {
      const calculatedEFW = fmf.calculateEFWFromBiometry({
        hc: hc.to("mm").toNumber(),
        ac: ac.to("mm").toNumber(),
        fl: fl.to("mm").toNumber(),
      });
      if (calculatedEFW) {
        efw = un({ value: calculatedEFW, unit: "g" })?.to("g");
        studyDate = study.date;
        return true;
      }
    } else if (data?.EFW?.value) {
      efw = un(data.EFW);
      studyDate = study.date;
      return true;
    }
    return false;
  });

  if (!efw || studyDate === undefined) return null;

  // 2. Get Gestational Age at efw calculation date
  const gaAtStudyDate = getPregnancyGAAt(currentPregnancy, studies, studyDate);
  if (!gaAtStudyDate?.weeks || !gaAtStudyDate?.days) return null;

  const gaAsDays = dayjs
    .duration(gaAtStudyDate.weeks, "weeks")
    .add(gaAtStudyDate.days, "days")
    .as("days");

  // 3. percentil del peso fetal resuelto del estudio  con la edad gestacional al momento del estudio
  const centileStudy = fmf.centileFromFW(efw.toNumber(), gaAsDays);

  if (!centileStudy) return null;

  const today = d();
  const gaToday = getPregnancyGAAt(currentPregnancy, studies, today.toDate());
  if (!gaToday?.weeks || !gaToday?.days) return null;

  const gaTodayAsDays = dayjs
    .duration(gaToday.weeks, "weeks")
    .add(gaToday.days, "days")
    .as("days");

  // 4. obtener el peso desde edada gestacional y percentil
  const fw = Number(fmf.calcEFW(gaTodayAsDays, centileStudy)?.toFixed(2));
  if (!fw) return null;

  return un({ value: fw, unit: "g" })?.to("g").toString();
};

export const getPatientCurrentPregnancyGAAt = (
  patient?: Patient,
  date?: Date | string
) => {
  if (!patient) return null;
  date = date ?? new Date();
  const currentPregnancy = getCurrentPregnancy(patient?.pregnancies);
  if (!currentPregnancy) return null;
  return getPregnancyGAAt(currentPregnancy, patient?.studies, date);
};

export const getPatientCurrentPregnancyGAToday = (patient?: Patient) => {
  if (!patient) return null;
  const currentPregnancy = getCurrentPregnancy(patient?.pregnancies);
  if (!currentPregnancy) return null;
  return getPregnancyGAToday(currentPregnancy, patient?.studies);
};

export const getPatientCurrentPregnancyEFW = (patient?: Patient) => {
  if (!patient) return null;
  const currentPregnancy = getCurrentPregnancy(patient?.pregnancies);
  if (!currentPregnancy) return null;
  return getCurrentPregnancyEFW(currentPregnancy, patient?.studies);
};

// return studies that are within the current pregnancy
export const getPregnancyStudies = (
  pregnancy?: Pregnancy | null,
  studies?: StudyWithJsonData[]
) => {
  if (!pregnancy || !studies) return null;
  const studyType = StudyType.pregnancy; // "pregnancy";
  const studySubType = StudySubType.obstetricUltrasound; //"obstetricUltrasound";
  const filteredStudies = studies?.filter((study) => {
    const currPregnancyStart = d(
      pregnancy.lastMenstrualPeriodDate ?? pregnancy.createdAt
    );
    const istWithinRange = d(study.date).isBetween(
      currPregnancyStart,
      d(),
      "day",
      "[]"
    );
    return (
      istWithinRange &&
      study.type === studyType &&
      study.subType === studySubType
    );
  });
  return filteredStudies;
};

export const getCurrentPregnancyStudies = (patient?: Patient | null) => {
  const currentPregnancy = getCurrentPregnancy(patient?.pregnancies);
  return getPregnancyStudies(currentPregnancy, patient?.studies);
};

export const getEstimatedDeliveryDate = (
  fumc?: string | number | Date | dayjs.Dayjs | null
) => {
  if (!fumc) return null;
  return d(fumc).add(40, "weeks");
};
// Devuelve en qué fecha va a ser determinada GA
export const getDateFromGA = (
  fumc?: string | number | Date | dayjs.Dayjs | null,
  ga?: number | null
) => {
  if (!fumc || !ga) return null;
  let weeks = Number(ga);
  let days;
  if (!Number.isInteger(Number(ga))) {
    const [w, d] = String(Number(ga)).split(".");

    weeks = Number(w);
    days = Number(d);
  }
  const res = d(fumc).add(weeks, "weeks");
  if (days) {
    return res.add(days, "days");
  }
  return res;
};
