import { Specialties } from "@/types";
export const LOCAL_STORAGE_LANG_KEY = "ALMA_LANG";
export const TIMEZONE = "Etc/UTC";
export const FREE_PLAN_MAX_PATIENTS = 15;
export const EMPTY_FIELD_STRING = "EMPTY_FIELD_STRING";
export const INFO_EMAIL = "info@alma-med.com";
export const OPERATIONS_EMAIL = "operations@alma-med.com";
export const specialties = [Specialties.obstetra, Specialties.ecografista];

export const MILTON_PROD_ID = "66a0c0df-ad5f-481d-bb78-28a2b0b9ba1e";
export const JAVI_PROD_ID = "ef1e2b13-a58a-43e8-b5fb-e551725a0380";
export const JAVI_PROD_2_ID = "d8e1259c-d9c5-480e-9b75-0fc3f9ce81d3";
