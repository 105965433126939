import { useQuery } from "@tanstack/react-query";
import { fetchPractitionerById } from "@/utils/fetchers";
interface Props {
  practitionerId?: string | null;
}
import { USE_PRACTITIONER_QUERY_KEY } from "./keys";

export const usePractitioner = ({ practitionerId }: Props) => {
  return useQuery({
    queryKey: [USE_PRACTITIONER_QUERY_KEY, practitionerId],
    queryFn: () => fetchPractitionerById(practitionerId),
    enabled: !!practitionerId,
    staleTime: Infinity,
    retry: 1,
  });
};
