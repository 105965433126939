import * as Sentry from "@sentry/nextjs";
import { type StudiesResponse } from "@/pages/api/patients/[id]/studies";
import { type PractitionerByIdResponse } from "@/pages/api/practitioners/[id]";
import { type PractitionerMeResponse } from "@/pages/api/practitioners";
import { type PregnancyByIdResponse } from "@/pages/api/pregnancies/[id]";
import { type PatientPregnanciesResponse } from "@/pages/api/patients/[id]/pregnancies";
import { type PatientByIdResponse } from "@/pages/api/patients/[id]";
import { type PatientByIdentificationNumberResponse } from "@/pages/api/patients/getByIdentificationNumber";
import { type Patient } from "@/types";

// import { type Practitioner } from "@/lib/db";

// const PROTOCOL =
//   process.env.NEXT_PUBLIC_VERCEL_ENV === "development" ? "http" : "https";
// // const HOST = process.env.NEXT_PUBLIC_VERCEL_URL;
// function getHost() {
//   if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production")
//     return process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL;
//   if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview")
//     return (
//       process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ??
//       process.env?.NEXT_PUBLIC_VERCEL_URL
//     );
//   if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development")
//     return process.env.NEXT_PUBLIC_VERCEL_URL;
// }
// const HOST = getHost();
// export const baseUrl = `${PROTOCOL}://${HOST}`;
export const baseUrl = ``;

export const fetchPatientsByPractitionerId = async (
  practitionerId?: string | null
) => {
  try {
    type Response = { patients: Patient[] };
    if (!practitionerId) return null;
    const res = await fetch(
      `${baseUrl}/api/patients?practitionerId=${practitionerId}`,
      {
        method: "GET",
        // cache: "force-cache",
      }
    );
    const response = (await res.json()) as Response;
    if (!res.ok || !response.patients) {
      throw new Error(
        `Error GET::/api/patients?practitionerId={id} | Response not ok or missing patients | Response:${JSON.stringify(res)}`
      );
    }

    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const updatePractitioner = async ({
  practitionerId,
  body = {},
}: {
  practitionerId?: string | null;
  body: Record<string, unknown>;
}) => {
  try {
    if (!practitionerId) return null;
    const res = await fetch(`${baseUrl}/api/practitioners/${practitionerId}`, {
      method: "PATCH",
      body: JSON.stringify(body),
    });

    const response = (await res.json()) as PractitionerByIdResponse;
    if (!res.ok || response?.error || !response?.practitioner) {
      const errorMsg = `Error: PATCH::${baseUrl}/api/practitioners/${practitionerId} | ${JSON.stringify(res)}`;
      throw new Error(response?.message ?? errorMsg);
    }

    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
export const fetchPractitionerById = async (practitionerId?: string | null) => {
  if (!practitionerId) return null;
  const res = await fetch(`${baseUrl}/api/practitioners/${practitionerId}`, {
    method: "GET",
    // cache: "force-cache",
  });

  const response = (await res.json()) as PractitionerByIdResponse;
  if (!res.ok || response?.error || !response?.practitioner) {
    Sentry.captureException(
      `Error: GET::${baseUrl}/api/practitioners/${practitionerId} | ${JSON.stringify(res)}`
    );
    throw new Error(response?.message ?? "Error");
  }

  return response;
};

export const createStudy = async ({
  body,
  patientId,
}: {
  body: Record<string, unknown>;
  patientId?: string;
}) => {
  if (!body || !patientId) {
    throw new Error("missing args");
  }

  const res = await fetch(`${baseUrl}/api/patients/${patientId}/studies`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  const response = (await res.json()) as StudiesResponse;
  if (!res.ok || response.error || !response.study) {
    Sentry.captureException(
      `Error: POST::${baseUrl}/api/patients/${patientId}/studies | ${JSON.stringify(res)}`
    );
    throw new Error(response?.message ?? "Error");
  }

  return response;
};

export const createPractitioner = async (userId?: string, body = {}) => {
  if (!userId) return null;
  const res = await fetch(`${baseUrl}/api/practitioners?userId=${userId}`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  const response = (await res.json()) as PractitionerMeResponse;
  if (!res.ok || response.error || !response.practitioner) {
    Sentry.captureException(
      `Error: POST::${baseUrl}/api/practitioners?userId=${userId} | ${JSON.stringify(res)}`
    );
    throw new Error(response?.message ?? "Error");
  }

  return response;
};
export type DeletePatientResponse = {
  error?: boolean;
  message: string;
  patient: Patient;
};
export const deletePatient = async ({
  practitionerId,
  patientId,
}: {
  practitionerId?: string;
  patientId?: string;
}) => {
  if (!practitionerId || !patientId) {
    throw new Error("missing args");
  }

  const res = await fetch(
    `${baseUrl}/api/patients?practitionerId=${practitionerId}`,
    {
      method: "DELETE",
      body: JSON.stringify({ practitionerId, patientId }),
    }
  );

  const response = (await res.json()) as DeletePatientResponse;
  if (!res.ok || response.error) {
    Sentry.captureException(
      `Error: DELETE::${baseUrl}/api/patients?practitionerId=${practitionerId} | ${JSON.stringify(res)}`
    );
    throw new Error(response?.message ?? "Error");
  }

  return response;
};

export const createPatient = async ({
  data,
  practitionerId,
}: {
  data: Record<string, unknown>;
  practitionerId?: string | null;
}) => {
  type Response = { patient: Patient };
  if (!data || !practitionerId) {
    Sentry.captureException(
      `Error: POST::${baseUrl}/api/patients?practitionerId=${practitionerId} | Missing args ${JSON.stringify({ data, practitionerId })}`
    );
    throw new Error("missing args");
  }

  const res = await fetch(
    `${baseUrl}/api/patients?practitionerId=${practitionerId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  if (!res.ok) {
    Sentry.captureException(
      `Error: POST::${baseUrl}/api/patients?practitionerId=${practitionerId} | ${JSON.stringify(res)}`
    );

    throw new Error("Error");
  }
  const response = (await res.json()) as Response;

  return response;
};

export const deleteStudy = async ({
  body,
  patientId,
}: {
  body: Record<string, unknown>;
  patientId?: string;
}) => {
  if (!body || !patientId) {
    throw new Error("missing args");
  }

  const res = await fetch(`${baseUrl}/api/patients/${patientId}/studies`, {
    method: "DELETE",
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    Sentry.captureException(
      `Error: DELETE::${baseUrl}/api/patients/${patientId}/studies | ${JSON.stringify(res)}`
    );
    throw new Error("[deleteStudy] Error");
  }

  const response = (await res.json()) as StudiesResponse;

  if (response?.error) {
    throw new Error(response?.message ?? "[deleteStudy] Error");
  }

  return response;
};

export const updatePregnancyByIdFn = async ({
  body,
  pregnancyId,
}: {
  body: Record<string, unknown>;
  pregnancyId?: string;
}) => {
  if (!body || !pregnancyId) {
    throw new Error("missing args");
  }

  const res = await fetch(`${baseUrl}/api/pregnancies/${pregnancyId}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    Sentry.captureException(
      `Error: PATCH::${baseUrl}/api/pregnancies/${pregnancyId} | ${JSON.stringify(res)}`
    );
    throw new Error("[updatePregnancyByIdFn] Error");
  }

  const response = (await res.json()) as PregnancyByIdResponse;

  if (response?.error || !response?.pregnancy) {
    Sentry.captureException(
      `Error: PATCH::${baseUrl}/api/pregnancies/${pregnancyId} | ${JSON.stringify(response)}`
    );
    throw new Error(response?.message ?? "[updatePregnancyByIdFn] Error");
  }

  return response;
};

export const updatePregnancy = async ({
  body,
  patientId,
}: {
  body: Record<string, unknown>;
  patientId?: string;
}) => {
  if (!body || !patientId) {
    throw new Error("missing args");
  }

  const res = await fetch(`/api/patients/${patientId}/pregnancies`, {
    method: "PATCH",
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    Sentry.captureException(
      `Error: PATCH::${baseUrl}/api/patients/${patientId}/pregnancies | ${JSON.stringify(res)}`
    );

    throw new Error("[updatePregnancy] Error");
  }

  const response = (await res.json()) as PatientPregnanciesResponse;

  if (response?.error) {
    Sentry.captureException(
      `Error: PATCH::${baseUrl}/api/patients/${patientId}/pregnancies | ${JSON.stringify(response)}`
    );

    throw new Error(response?.message ?? "[updatePregnancy] Error");
  }
  return response;
};

export const getPatientById = async (
  patientId?: string | null,
  practitionerId?: string | null
) => {
  if (!patientId || !practitionerId) return null;
  const res = await fetch(
    `${baseUrl}/api/patients/${patientId}?practitionerId=${practitionerId}`,
    {
      method: "GET",
      // cache: "force-cache",
    }
  );
  if (!res.ok) {
    Sentry.captureException(
      `Error: GET::$${baseUrl}/api/patients/${patientId}?practitionerId=${practitionerId} | ${JSON.stringify(res)}`
    );

    throw new Error("[getPatientById] Error");
  }

  const response = (await res.json()) as PatientByIdResponse;

  if (response?.error) {
    Sentry.captureException(
      `Error: GET::$${baseUrl}/api/patients/${patientId}?practitionerId=${practitionerId} | ${JSON.stringify(response)}`
    );

    throw new Error(response?.message ?? "[getPatientById] Error");
  }
  return response;
};

export const getPatientByIdentificationNumber = async (
  identificationNumber?: string | null
) => {
  if (!identificationNumber) return null;
  const res = await fetch(
    `${baseUrl}/api/patients/getByIdentificationNumber?identificationNumber=${identificationNumber}`,
    {
      method: "GET",
    }
  );
  if (!res.ok) {
    Sentry.captureException(
      `Error: GET::${baseUrl}/api/patients/getByIdentificationNumber?identificationNumber=${identificationNumber} | ${JSON.stringify(res)}`
    );
    throw new Error("[getPatientByIdentificationNumber] Error");
  }

  const response = (await res.json()) as PatientByIdentificationNumberResponse;

  if (response?.error) {
    Sentry.captureException(
      `Error: GET::${baseUrl}/api/patients/getByIdentificationNumber?identificationNumber=${identificationNumber} | ${JSON.stringify(response)}`
    );

    throw new Error(
      response?.message ?? "[getPatientByIdentificationNumber] Error"
    );
  }
  return response;
};
