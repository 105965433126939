import React, { createContext, useContext, useMemo, useState } from "react";
import { usePractitioner } from "@/utils/client/queries/usePractitioner";
import { WeightCalculatorOptions, type Preferences } from "@prisma/client";
import usePractitionerId from "@/hooks/usePractitionerId";

import { intergrowth, fmf, barcelona } from "@/lib/core";
import { PractitionerByIdResponse } from "@/pages/api/practitioners/[id]";
import { type Refetch } from "@/types";

function getProvider(pref: WeightCalculatorOptions) {
  switch (pref) {
    case WeightCalculatorOptions.fmf:
      return fmf;
    case WeightCalculatorOptions.intergrouth:
      return intergrowth;
    case WeightCalculatorOptions.barcelona:
      return barcelona;
    default:
      return fmf;
  }
}

export type WeightProvider = ReturnType<typeof getProvider>;

type PractitionerWeightContextValue = {
  isLoading: boolean;
  preferences?: Preferences | null;
  weightPref: WeightCalculatorOptions;
  isFmf: boolean;
  isIntergrouth: boolean;
  provider: WeightProvider;
  refetch: Refetch<PractitionerByIdResponse>;
  setWeightPref: (pref: WeightCalculatorOptions) => void;
  resetWeightPref: () => void;
};

const fallback = {
  isLoading: true,
  preferences: null,
  weightPref: WeightCalculatorOptions.fmf,
  isFmf: true,
  isIntergrouth: false,
  provider: fmf,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetch: (async () => ({})) as Refetch<PractitionerByIdResponse>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setWeightPref: (pref: WeightCalculatorOptions) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  resetWeightPref: () => {},
};
export const PractitionerWeightContext =
  createContext<PractitionerWeightContextValue>(fallback);

export const PractitionerWeightProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const practitionerId = usePractitionerId();
  const { data, isLoading, refetch } = usePractitioner({ practitionerId });

  // Estado para sobrescribir manualmente el provider
  const [manualWeightPref, setManualWeightPref] =
    useState<WeightCalculatorOptions | null>(null);
  // Determina el `weightPref` a usar (manual o basado en datos)
  const weightPref = useMemo(
    () =>
      manualWeightPref ??
      data?.practitioner?.preferences?.weightCalculator ??
      WeightCalculatorOptions.fmf,
    [manualWeightPref, data]
  );

  const provider = useMemo(() => getProvider(weightPref), [weightPref]);

  const value = useMemo(
    () => ({
      isLoading,
      preferences: data?.practitioner?.preferences,
      weightPref,
      isFmf: weightPref === WeightCalculatorOptions.fmf,
      isIntergrouth: weightPref === WeightCalculatorOptions.intergrouth,
      provider,
      refetch,
      setWeightPref: setManualWeightPref, // Permite sobrescribir manualmente
      resetWeightPref: () => setManualWeightPref(null), // Restablece al valor predeterminado
    }),
    [isLoading, data, weightPref, provider, refetch]
  );

  return (
    <PractitionerWeightContext.Provider value={value}>
      {children}
    </PractitionerWeightContext.Provider>
  );
};
